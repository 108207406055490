import React from 'react'

import { FourOFourLayout } from '../layouts/FourOFourLayout'

function FourOFourPage(): JSX.Element {
  return <FourOFourLayout />
}

// tslint:disable-next-line: no-default-export
export default FourOFourPage
